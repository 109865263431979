import React from 'react'
import '../Styles/card-styles.css'
import Tilty from 'react-tilty'
import { Link } from 'react-router-dom'

const Projects = () => {

function console() {
  console.log("lkdfjslkdfjals")
}

  return (
    <div className="cardRow">
      <Tilty>
        <div className='cardContainer'>
            <div className='image1' alt='card container' onClick={ console }></div>
            <h2 className='title' style={{ color:'black'}}><a href='https://mainegomedia.github.io/blockchain-learning-tool/'>Blockchain Learning</a></h2>
            <p className='description'>Personal project for educating individuals how the blockchain works in a simple
            easy to understand way. <br/> <b>Bootstrap | ReactJS | Coinmarketcap API</b> </p>
        </div>
      </Tilty>
      <Tilty>
        <div className='cardContainer'>
            <div className='image3' alt='card container'></div>
            <h2 className='title' style={{ color:'black'}}><a href='https://mainegomedia.github.io/space2space/'>Space 2 Space</a></h2>
            <p className='description'>UI design for a mock web application that woould allow users to locate prospective roomates
            in different countries. For foreign exchange students, visitors on visa's etc...<br/> <b>Bootstrap | HTML | CSS| Javascript</b></p>
        </div>
      </Tilty>
      <Tilty>
        <div className='cardContainer' a href='https://mainegomedia.github.io/utv-landingpage/'>
            <div className='image2' alt='card container'></div>
            <h2 className='title' style={{ color:'black'}}><a href='https://mainegomedia.github.io/utv-landingpage/'>UTV Token</a></h2>
            <p className='description'>Landing page design concept for a current cryptocurrency token UTV Token used by Upper Tier Video
            for creating an ecosystem for player NFTs.<br/> <b>ReactJS | HTML | CSS | Javascript</b> </p>
        </div>
      </Tilty>

    </div>
  )
}

export default Projects
