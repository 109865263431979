import React from 'react'
import Form from '../Components/Form'

const Contact = () => {
  return (
    <div>
      <Form />
    </div>
  )
}

export default Contact