import React from 'react'
import '../App.css';


const Home = () => {
  return (
    <div>
      <div className='nameBox'>JERMAINE<br/><span>FOSTER</span></div>
      <div className='tagtitle'>Developer & Designer</div>
    </div>
  )
}

export default Home;
